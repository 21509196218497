import idx from "idx";
import { ColumnProps } from "antd/lib/table";
import { Button, Checkbox } from "antd";
import { navigate } from "gatsby";
import {
  GetBlogPostList,
  GetBlogPostListVariables,
  GetBlogPostList_blogPosts_items as BlogPost,
} from "./types/GetBlogPostList";
import GET_BLOG_POST_LIST from "./getBlogPostList.graphql";
import React, { FC, useCallback, useMemo } from "react";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import GraphQLTable, {
  GraphQLTableProps,
  useColumnSearch,
  creationTime,
  lastModificationTime,
} from "components/graphQLTable";
import routes from "routes";
import DeleteBlogPostButton from "components/deleteBlogPostButton";

type BlogPostTableProps = GraphQLTableProps<
  BlogPost,
  GetBlogPostList,
  GetBlogPostListVariables
>;

type BlogPostColumnProps = ColumnProps<BlogPost>;

const BlogPostList: FC = () => {
  const gotoBlogPostEdit = useCallback(
    (id: string) => () => navigate(routes.blogPostEditToPath({ id })),
    []
  );

  const gotoBlogPostCreate = useCallback(
    () => navigate(routes.blogPostCreate),
    []
  );

  const getDataSource = useCallback<BlogPostTableProps["getDataSource"]>(
    data => idx(data, x => x.blogPosts),
    []
  );

  const { createColumn: createTitleColumn } = useColumnSearch<BlogPost>(
    "title"
  );

  const { createColumn: createSubtitleColumn } = useColumnSearch<BlogPost>(
    "subtitle"
  );

  const columns = useMemo<Array<BlogPostColumnProps>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      createTitleColumn({ title: "Заголовок", sorter: true }),
      createSubtitleColumn({ title: "Подзаголовок", sorter: true }),
      {
        align: "center",
        dataIndex: "isPublished",
        title: "Опубликована",
        render: value => <Checkbox checked={value} />,
        sorter: true,
      },
      creationTime,
      lastModificationTime,
      {
        key: "action",
        render: (_text, { id, title }) => (
          <>
            <Button
              className="mr-1"
              ghost={true}
              icon="edit"
              size="small"
              type="primary"
              onClick={gotoBlogPostEdit(id)}
            >
              Ред.
            </Button>
            <DeleteBlogPostButton
              blogPostId={parseInt(id, 10)}
              blogPostName={title}
              options={{ refetchQueries: ["GetBlogPostList"] }}
            />
          </>
        ),
      },
    ],
    [createSubtitleColumn, createTitleColumn, gotoBlogPostEdit]
  );

  return (
    <Authorize>
      <MainLayout
        title="Новости"
        extra={
          <Button icon="plus" type="primary" onClick={gotoBlogPostCreate}>
            Создать новость
          </Button>
        }
      >
        <GraphQLTable<BlogPost, GetBlogPostList, GetBlogPostListVariables>
          columns={columns}
          query={GET_BLOG_POST_LIST}
          getDataSource={getDataSource}
        />
      </MainLayout>
    </Authorize>
  );
};

export default BlogPostList;
