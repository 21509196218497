import { notification } from "antd";
import React, { FC, useCallback } from "react";
import DELETE_BLOGPOST from "./deleteBlogPost.graphql";
import {
  DeleteBlogPost,
  DeleteBlogPostVariables,
} from "./types/DeleteBlogPost";
import GraphQLConfirmButton, {
  GraphQLConfirmButtonProps,
} from "components/graphQLConfirmButton";

type DeleteBlogPostButtonBaseProps = GraphQLConfirmButtonProps<
  DeleteBlogPost,
  DeleteBlogPostVariables
>;

interface DeleteBlogPostButtonProps
  extends Omit<
    DeleteBlogPostButtonBaseProps,
    "mutation" | "title" | "onCompleted"
  > {
  blogPostId: number;
  blogPostName: string;
}

type Props = DeleteBlogPostButtonProps;

const DeleteBlogPostButton: FC<Props> = ({
  blogPostId: id,
  blogPostName,
  options: { variables, ...options } = {},
  ...props
}) => {
  const handleCompleted = useCallback<
    NonNullable<DeleteBlogPostButtonBaseProps["onCompleted"]>
  >(
    ({ errors }) =>
      !errors &&
      notification.success({
        message: "Успех!",
        description: (
          <>
            Роль <strong>{blogPostName}</strong> была успешно удалена
          </>
        ),
      }),
    [blogPostName]
  );

  return (
    <GraphQLConfirmButton<DeleteBlogPost, DeleteBlogPostVariables>
      mutation={DELETE_BLOGPOST}
      options={{
        variables: { id, ...variables },
        ...options,
      }}
      title={
        <>
          Вы уверены что хотите удалить новость <strong>{blogPostName}</strong>?
        </>
      }
      onCompleted={handleCompleted}
      {...props}
    />
  );
};

export default DeleteBlogPostButton;
